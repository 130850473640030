import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
// ? Icons
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import TelegramIcon from '@mui/icons-material/Telegram'
import TwitterIcon from '@mui/icons-material/Twitter'
import { styled } from '@mui/material'
// ? Material UI
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Image from 'next/image'
// ? Next Js
import Link from 'next/link'
import React from 'react'
import { getStrapiImage } from '../../lib/api'
import PopUp from '../popUp/PopUp'
// ? Assets

const Section = styled('section')(({ theme }) => ({
  background: theme.palette.grey.A100,
  position: 'relative',
  overflow: 'hidden',
}))

interface Props {
  data: Company
  soumettre: Cta
}

const Footer: React.FC<Props> = ({ data, soumettre }) => {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const sections = [
    {
      title: 'Company',
      links: [
        {
          label: 'Uptodate Developers',
          path: 'https://uptodatedevelopers.com',
        },
      ],
    },
    {
      title: 'Ressources',
      links: [
        { label: 'Accueil', path: '/' },
        { label: 'Contact', path: '/#contact' },
      ],
    },
  ]

  return (
    <Section>
      <PopUp open={open} handleClose={handleClose} />
      <Container>
        <Grid
          container
          sx={{ py: { xs: 4, md: 8 }, position: 'relative', zIndex: 2 }}
          spacing={{ xs: 2, md: 4 }}
        >
          <Grid item xs={12} sm={6} md={3}>
            <Stack spacing={2}>
              <Box sx={{ width: 250, height: 100, position: 'relative' }}>
                <Image
                  src={getStrapiImage(data?.logo)}
                  // blurDataURL={getStrapiImagePlaceholder(data?.logo)}
                  // placeholder="blur"
                  alt="updev"
                  layout="fill"
                  objectFit="contain"
                ></Image>
              </Box>
              <Typography color="text.secondary" component="a">
                {data?.description}
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography component="h1" variant="h6" color="white">
              {sections[0].title}
            </Typography>
            <Stack spacing={1} sx={{ mt: 2 }}>
              {sections[0].links.map((_) => (
                <a
                  href={`${_.path}`}
                  target="_blank"
                  key={_.label}
                  rel="noreferrer"
                >
                  <Typography color="text.secondary" component="a">
                    {_.label}
                  </Typography>
                </a>
              ))}
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography component="h1" variant="h6" color="white">
              {sections[1].title}
            </Typography>
            <Stack spacing={1} sx={{ mt: 2 }}>
              {sections[1].links.map((_) => (
                <Link href={`${_.path}`} passHref key={_.label}>
                  <Typography color="text.secondary" component="a">
                    {_.label}
                  </Typography>
                </Link>
              ))}
              <a
                {...(soumettre?.lien
                  ? {
                      component: 'a',
                      href: soumettre?.lien,
                      target: '_blank',
                      rel: 'noreferrer',
                    }
                  : {
                      onClick: handleOpen,
                    })}
              >
                <Typography color="text.secondary" component="a">
                  {soumettre?.texte}
                </Typography>
              </a>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography component="h1" variant="h6" color="white">
              Contact
            </Typography>

            <List aria-label="address" dense>
              <Link
                href={`mailto:${data?.email}?subject=From Website - Your subject`}
                passHref
              >
                <ListItemButton disableGutters>
                  <ListItemIcon>
                    <AlternateEmailIcon sx={{ color: 'white' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={data?.email}
                    sx={{ color: 'white', wordBreak: 'break-all' }}
                  />
                </ListItemButton>
              </Link>
              <a
                href={`${data?.rs?.telegram}`}
                target="_blank"
                rel="noreferrer"
              >
                <ListItemButton disableGutters>
                  <ListItemIcon>
                    <TelegramIcon sx={{ color: 'white' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Rejoignez notre chaîne telegram"
                    sx={{ color: 'white', wordBreak: 'break-all' }}
                  />
                </ListItemButton>
              </a>
            </List>
          </Grid>
        </Grid>

        <Divider sx={{ borderColor: '#dedede' }} />
        <Stack
          justifyContent="space-between"
          alignItems="center"
          direction={{ xs: 'column-reverse', md: 'row' }}
          spacing={2}
          sx={{ py: 2 }}
        >
          <Typography color="white" component="p" textAlign="center">
            Copyright &copy; {new Date().getFullYear()}{' '}
            <Typography
              color="secondary"
              href="https://uptodatedevelopers.com"
              component="a"
              target="_blank"
            >
              Uptodate Developers
            </Typography>
            . All rights reserved
          </Typography>
          <Stack direction="row" spacing={1}>
            <a
              href={`${data?.rs?.facebook}`}
              target="_blank"
              title="Facebook of uptodate developers"
              rel="noopener noreferrer"
            >
              <IconButton>
                <FacebookIcon sx={{ color: 'white' }} aria-label="facebook" />
              </IconButton>
            </a>
            <a
              href={data?.rs?.instagram}
              target="_blank"
              title="Instagram of uptodate developers"
              rel="noopener noreferrer"
            >
              <IconButton>
                <InstagramIcon sx={{ color: 'white' }} aria-label="instagram" />
              </IconButton>
            </a>
            <a
              href={data?.rs?.twitter}
              target="_blank"
              title="Twitter of uptodate developers"
              rel="noopener noreferrer"
            >
              <IconButton>
                <TwitterIcon sx={{ color: 'white' }} aria-label="twitter" />
              </IconButton>
            </a>
            <a
              href={data?.rs?.linkedin}
              target="_blank"
              title="LinkedIn of uptodate developers"
              rel="noopener noreferrer"
            >
              <IconButton>
                <LinkedInIcon sx={{ color: 'white' }} aria-label="linkedIn" />
              </IconButton>
            </a>
          </Stack>
        </Stack>
      </Container>
    </Section>
  )
}

export default Footer
